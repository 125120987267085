import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import img_logo from '../assets/images/RFDS.svg';
import { DeleteIndexDB } from '../indexedDB';

const NavMenu = () => {

    const [DisplayName, setDisplayName] = useState('');

    useEffect(() => {
        GetDisplayName();
    }, []);

    const GetDisplayName = () => {
        let accountInfo = localStorage.getItem('accountInfo')
        if (accountInfo) {
            let Json_AccountInfo = JSON.parse(accountInfo);
            if (Json_AccountInfo?.data?.response) {
                let Name = JSON.parse(Json_AccountInfo?.data?.response)?.displayName;
                if (Name) {
                    setDisplayName(Name)
                }
            }
        }
    }

    const LogOut = () => {
        const isConfirmed = window.confirm('Are you sure you want to Logout?');

        if (isConfirmed) {
            DeleteIndexDB();
            localStorage.clear();
            window.location.href = "/login";
        }
    }

    return (
        <header style={{ backgroundColor: "#FFF" }}>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <div style={{ float: "left" }}>
                    <NavbarBrand tag={Link} to="/">
                        <img src={img_logo} alt="logo" style={{ height: '48px', cursor: 'pointer', marginRight: "10px" }} />
                        <strong>
                            RFDS IT Portal
                        </strong>
                    </NavbarBrand>
                </div>
                <div style={{ float: "right" }}>
                    {DisplayName &&
                        <>
                            <strong>
                                <span className="title" style={{ marginTop: "5px" }}>
                                    {DisplayName}</span></strong> &nbsp;&nbsp;&nbsp;
                            <Button className={"btn-primary"} onClick={(e) => { e.preventDefault(); LogOut(); }}>Logout</Button>
                        </>
                    }
                </div>
                {/*<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />*/}
                {/*<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>*/}
                {/*    <ul className="navbar-nav flex-grow">*/}
                {/*        <NavItem>*/}
                {/*            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                {/*        </NavItem>*/}
                {/*        <NavItem>*/}
                {/*            <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
                {/*        </NavItem>*/}
                {/*        <NavItem>*/}
                {/*            <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
                {/*        </NavItem>*/}
                {/*    </ul>*/}
                {/*</Collapse>*/}
            </Navbar>
        </header>
    );
}

export default NavMenu;