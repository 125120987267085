import React, { useState, useEffect } from 'react';
import { Row, Card } from 'reactstrap';
import axios from 'axios';

//const CLIENT_ID = 'XKnaejJEttLmS0Rjmj92Bgzu9T1JrAZr';
//const REDIRECT_URI = 'http://localhost:44442/login';
//const CLIENT_SECRET = 'ATOAOT2k9vFdMuYjE_VnVYpyz_Z30kNvMyehF01z8CwH_fAhhhf53Gg-oBmUhamCLs797582A227';

const CLIENT_ID = 'SzLtTeySEB4fmK8T87uCZejDmO8UQOoQ';
const CLIENT_SECRET = 'ATOA-M2XcUWA2v5l6UEVN87Ik79Yx69-HhzU5DleC1OjxMDNer9RoS8uySsx2oy0pjaH283DCD46';
const REDIRECT_URI = 'https://dotnet-rfds-portal.agiletechnologies.in/login';

const AUTHORIZATION_URL = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${CLIENT_ID}&scope=read%3Ajira-user%20read%3Arequest.attachment%3Ajira-service-management%20write%3Arequest.attachment%3Ajira-service-management%20read%3Ame%20read%3Aaccoun%20read%3Aservicedesk-request%20write%3Aservicedesk-request%20read%3Aservicemanagement-insight-objects%20manage%3Aservicedesk-customer&redirect_uri=${REDIRECT_URI}&state=statex&response_type=code&prompt=consent`;

const Login = ({ setIsAuthenticated }) => {

    useEffect(() => {

        const code = new URLSearchParams(window.location.search).get('code');
        if (code) {
            exchangeCodeForToken(code);
        }

        const token = localStorage.getItem('accessToken');
        if (token !== null && token !== undefined && token !== "") {
            console.log(token)
            window.location.href = "/home";
        }
    }, []);

    const exchangeCodeForToken = async (code) => {
        try {
            const response = await axios.post('https://auth.atlassian.com/oauth/token', {
                grant_type: 'authorization_code',
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                code: code,
                redirect_uri: REDIRECT_URI,
            });

            const accessToken = response.data.access_token;
            localStorage.setItem('accessToken', accessToken);

            const accountInfo = await axios.get(`/JiraApi/GetJiraAccountInfo?token=${accessToken}`);

            localStorage.setItem('accountInfo', JSON.stringify(accountInfo));

            setIsAuthenticated(true);
            window.location.href = "/home";
            //navigate('/home'); // Redirect after successful authentication
        } catch (error) {
            console.error('Error exchanging code for token', error);
        }
    };

    //   const fetchAtlassianUserInfo = async (accessToken) => {
    //     try {
    //       const response = await axios.get('https://api.atlassian.com/me', {
    //         headers: {
    //           'Authorization': `Bearer ${accessToken}`,
    //           'Accept': 'application/json'
    //         }
    //       });

    //       setUserInfo(response.data);
    //     } catch (error) {
    //       console.error('Error fetching Atlassian user info', error);
    //     }
    //   };

    return (
        <div>
            <Row>
                <Card className="text-center">
                    <br /><br /><br /><br />
                    <a href={AUTHORIZATION_URL}><h4> Login to JIRA Create Request.</h4></a>
                    {/*{userInfo && (*/}
                    {/*    <div>*/}
                    {/*        <h2>User Information</h2>*/}
                    {/*        <div><strong>Access Token:</strong> {accessToken}</div>*/}
                    {/*        <div><strong>Account ID:</strong> {userInfo.account_id}</div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <br /><br /><br /><br />
                </Card>
            </Row >
        </div >
    );
}

export default Login;
