import React, { Component, useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import ServiceReq from "./components/ServiceReq";
import Home from "./components/Home";
import Login from "./components/Login";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        // Check local storage or session for authentication token or state
        const token = localStorage.getItem('accessToken');
        console.log(token);
        if (token !== null && token !== undefined && token !== "") {
            setIsAuthenticated(true);
        }
        else{
            setIsAuthenticated(false);
        }
    }, []);

    return (
        <Layout>
            <Routes>
                <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
                <Route path="/home" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
                <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                <Route path="service_req/:id" element={isAuthenticated ? <ServiceReq /> : <Navigate to="/login" />} />
            </Routes>
        </Layout>
    );
}

export default App;
