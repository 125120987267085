/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Input, Button } from 'reactstrap';
import { ReadIndexDBData, StoreJIRAReq, ClearTable } from '../indexedDB'
import { useEffect, useState } from "react";
//import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import { MarkdownTransformer } from '@atlaskit/editor-markdown-transformer';
//import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
//import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer';
//import { DOMParser, DOMSerializer } from 'prosemirror-model';

const ServiceReq = (props) => {
    //const editorConfiguration = {
    //    toolbar: ['heading', '|',
    //        'fontSize', 'fontFamily', '|',
    //        //'fontColor', 'fontBackgroundColor', '|',
    //        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|',
    //        'alignment', '|',
    //        'numberedList', 'bulletedList', '|',
    //        'outdent', 'indent', '|',
    //        //'todoList', 'link', 'imageUpload', 'mediaEmbed', 'specialCharacters', 'removeFormat',
    //        'blockQuote', 'insertTable', '|',
    //        'undo', 'redo'],
    //    innerHeight: "400px"
    //};

    const [isLoading, setIsLoading] = useState(false);
    const [SuccessMessage, setSuccessMessage] = useState();
    const { id } = useParams();
    const [JIRAItems, setJIRAItems] = useState([]);

    const [selIndex, setselIndex] = useState(null);
    const [selItem, setselItem] = useState(null);
    const [OpenItems, setOpenItems] = useState(true);
    const [reqDesc, setreqDesc] = useState('');

    //ATATT3xFfGF0Uet8DFyzLfjyVfoI3vOr3oe0TY342qFDKR_y9HnrPRH9L8djGOlmtqqUWo7QLr-6wUx-cNYl7CqJefvDe2NvoPpCwwkKetGOiNb9jL0JK2K_WdcFXYornRP4tElLnSU9JoN6lMnKaoUbPDAsxcwgjmFuxnDySYadCi6dS8ZvZO0=DDEBD3EE
    const GetGroupItems = async () => {
        const data = await ReadIndexDBData("jira_data");
        let GroupID = id;
        let filteredJIRAItem = data.filter(item => item.groupIds.includes(GroupID))
        setJIRAItems(filteredJIRAItem);

        //setselIndex(0)
        //setselItem(filteredJIRAItem[0])
    }

    useEffect(() => {
        GetGroupItems();
    }, []);

    useEffect(() => {
        setSuccessMessage("");
    }, [selIndex]);

    function base64toFile(base64Data, filename) {
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];

        const byteNumbers = new Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            byteNumbers[i] = byteString.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeString });

        return new File([blob], filename, { type: mimeString });
    }

    useEffect(() => {
        // Define a function to call when online status changes
        const updateOnlineStatus = async () => {
            console.log(navigator.onLine ? 'Online' : 'Offline');
            const token = localStorage.getItem('accessToken');
            if (navigator.onLine) {
                const data = await ReadIndexDBData("requests");
                const tickets = data.filter(item => Object.hasOwn(item, 'requestFieldValues') || Object.hasOwn(item, 'fields'));

                tickets.map((ticket) => {
                    let objReq = {};

                    var hasCustomForm = false;
                    try {
                        hasCustomForm = ticket.hasCustomForm;
                    } catch (e) {

                    }

                    if (hasCustomForm === true) {
                        objReq["fields"] = ticket.fields;
                    }
                    else {
                        objReq["serviceDeskId"] = ticket.serviceDeskId;
                        objReq["requestTypeId"] = ticket.requestTypeId;
                        objReq["raiseOnBehalfOf"] = ticket.raiseOnBehalfOf;
                        objReq["requestFieldValues"] = ticket.requestFieldValues;
                    }

                    fetch(`/JiraApiBasic/PostJIRARequest?hasCustomForm=${hasCustomForm}`, {
                        method: "POST",
                        body: JSON.stringify({
                            data: objReq
                        }),
                        headers: {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    }).then(response => response.json())
                        .then(async (j) => {
                            if (j?.message) {
                                const issueIdOrKey = j.message.issueId;

                                const isPublic = true;

                                let attachments = data.filter(item => !Object.hasOwn(item, 'requestFieldValues') && item.ticketUid === ticket.ticketUid);

                                if (attachments) {
                                    const tempAttachmentIds = [];

                                    for (let i = 0; i < attachments.length; i++) {
                                        const formData = new FormData();

                                        var file = base64toFile(attachments[i].file, attachments[i].name)
                                        formData.append("file", file);

                                        const uploadResponse = await fetch(`/JiraApiBasic/UploadTemporaryFileToJIRA?serviceDeskId=1`, {
                                            method: "POST",
                                            body: formData
                                        });

                                        if (uploadResponse.ok) {
                                            const uploadResult = await uploadResponse.json();
                                            const response = JSON.parse(uploadResult.response);
                                            const tempFileId = response.temporaryAttachments[0].temporaryAttachmentId;

                                            tempAttachmentIds.push(tempFileId);
                                        }
                                    }

                                    // Once all files are uploaded, attach them to the ticket
                                    if (tempAttachmentIds.length > 0) {
                                        fetch(`/JiraApiBasic/AttachFileToJIRAIssue?issueIdOrKey=${issueIdOrKey}&isPublic=${isPublic}`, {
                                            method: 'POST',
                                            body: JSON.stringify(tempAttachmentIds),
                                            headers: {
                                                'Content-type': 'application/json; charset=UTF-8',
                                            },
                                        })
                                            .then(response => response.json())
                                            .then(attachResponse => {
                                                console.log('Attachment Response:', attachResponse);
                                            })
                                            .catch(error => {
                                                console.error('Error attaching file:', error);
                                            });
                                    }

                                }

                                //alert("Ticket created successfully.");
                            } else {
                                //alert("Failed to create ticket.");
                            }
                        })
                });

                ClearTable('requests');

            }
        };

        // Add event listeners for online and offline events
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        // Log the initial status
        updateOnlineStatus();

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    const SelectedItemView = ({ selItem, setOpenItems, OpenItems }) => {
        return (
            <Row className={"p10"} onClick={() => setOpenItems(true)}>
                <div className="col-2 text-center">
                    <img alt="" src={selItem.icon._links.iconUrls["_48x48"]} className={"img_JIRAitem"} />
                </div>
                <div className="col-9">
                    <span className={"title"}>{selItem.name}</span>< br />
                    < br />
                    {OpenItems === false && <div dangerouslySetInnerHTML={{ __html: selItem.description.replaceAll("\n", "<br />") }}>
                    </div>
                    }
                </div>
                <div className="col-1">
                    {OpenItems && <span className={"title"}>&#x25B2;</span>}
                    {OpenItems === false && <span className={"title"}>&#x25BC;</span>}
                </div>
            </Row>)
    }

    const ItemData = ({ item, setOpenItems, selIndex, index }) => {
        return (
            <Row className={"p10"} style={{ backgroundColor: index === selIndex ? "#EEF3F3" : "#FFF" }}
                onClick={() => { setselIndex(index); setselItem(item); setOpenItems(false); }}>
                <div className="col-2 text-center">
                    <img alt="" src={item.icon._links.iconUrls["_48x48"]} className={"img_JIRAitem"} />
                </div>
                <div className="col-10">
                    <span className={"title"}>{item.name}</span>< br />
                    <div dangerouslySetInnerHTML={{ __html: item.description.replaceAll("\n", "<br />") }}>
                    </div>
                </div>
            </Row>)
    }

    const CreateServiceReq = async () => {
        //const transformer = new MarkdownTransformer(defaultSchema);
        //let dataHTML = transformer.parse(reqDesc);
        //console.log(dataHTML)

        //const dom = document.implementation.createHTMLDocument();
        //dom.body.innerHTML = reqDesc;
        //console.debug('toMD', { dom });

        //const pmNode = DOMParser.fromSchema(defaultSchema).parse(dom);
        //const transformer1 = new WikiMarkupTransformer(defaultSchema);
        //const md = transformer1.encode(pmNode);
        //console.log(md)

        let objReq = {};

        //objReq["serviceDeskId"] = id;
        const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));

        let objField = {};

        if (document.getElementById("diverrorsummary"))
            document.getElementById("diverrorsummary").style.display = "none";

        if (document.getElementById("summary")) {
            if (document.getElementById("summary").value === "") {
                document.getElementById("summary").focus();
                document.getElementById("diverrorsummary").style.display = "";
                return;
            }
        }

        if (document.getElementById("diverrordescription")) {
            document.getElementById("diverrordescription").style.display = "none";
            if (reqDesc === "") {
                document.getElementById("diverrordescription").style.display = "";
                return;
            }
        }

        let reqFields = selItem?.requestFields?.requestTypeFields;
        let ext_Form_fields = selItem?.ext_form;
        let description = reqDesc;
        let hasCustomForm = false;

        for (var i = 0; i < reqFields?.length; i++) {
            if (["summary", "description", "attachment"].includes(reqFields[i].fieldId)) {

            }
            else if (reqFields[i].fieldId.toString().indexOf("customfield_") >= 0) {

                document.getElementById("diverror" + reqFields[i].fieldId.toString()).style.display = "none";
                if (reqFields[i]?.required === true && document.getElementById(reqFields[i].fieldId).value === "") {
                    document.getElementById(reqFields[i].fieldId).focus();
                    document.getElementById("diverror" + reqFields[i].fieldId.toString()).style.display = "";
                    return;
                }

                if (reqFields[i]?.jiraSchema?.system === null && reqFields[i]?.jiraSchema?.type === "option") {
                    let value = {};
                    value["id"] = document.getElementById(reqFields[i].fieldId).value;
                    objField[reqFields[i].fieldId] = value;
                }
                else {
                    objField[reqFields[i].fieldId] = document.getElementById(reqFields[i].fieldId).value;
                }
            }
        }

        if (reqFields.length <= 1 && ext_Form_fields !== null && ext_Form_fields?.design?.layout !== null) {
            let layout = ext_Form_fields.design.layout;
            for (var i = 0; i < layout.length; i++) {
                if (layout[i]?.content !== undefined) {
                    for (var j = 0; j < layout[i].content.length; j++) {
                        if (layout[i].content[j]?.content !== undefined && layout[i].content[j]?.content !== null) {
                            for (var k = 0; k < layout[i].content[j]?.content.length; k++) {
                                if (layout[i].content[j]?.content[k]?.content !== undefined && layout[i].content[j]?.content[k].content.length === 1) {
                                    let Attr = layout[i].content[j].content[k].content[0].attrs;
                                    let ID = Attr.parameters.id;
                                    if (ext_Form_fields?.design?.questions[ID] !== null && ext_Form_fields?.design?.questions[ID] !== undefined) {
                                        let FieldID = ext_Form_fields?.design?.questions[ID].jiraField;

                                        document.getElementById("diverror" + FieldID).style.display = "none";

                                        if (ext_Form_fields.design?.questions[Attr.parameters.id]?.validation?.rq === true && document.getElementById(FieldID).value === "") {
                                            document.getElementById(FieldID).focus();
                                            document.getElementById("diverror" + FieldID).style.display = "";
                                            return;
                                        }

                                        hasCustomForm = true;

                                        description = description + "\n" + ext_Form_fields.design?.questions[Attr.parameters.id].label + " : " + document.getElementById(FieldID).value;

                                        //if (["cl", "cd"].includes(ext_Form_fields.design?.questions[Attr.parameters.id].type)) {
                                        //    let value = {};
                                        //    value["id"] = document.getElementById(FieldID).value;
                                        //    objField[FieldID] = value;
                                        //}
                                        //else {
                                        //    objField[FieldID] = document.getElementById(FieldID).value;
                                        //}
                                    }
                                }
                            }
                        }


                        if (layout[i].content[j]?.content === null && layout[i].content[j]?.attrs !== undefined && layout[i].content[j]?.attrs !== null) {

                            let Attr = layout[i].content[j].attrs;
                            let ID = Attr.parameters.id;
                            if (ext_Form_fields?.design?.questions[ID] !== null && ext_Form_fields?.design?.questions[ID] !== undefined) {
                                let FieldID = ext_Form_fields?.design?.questions[ID].jiraField;

                                document.getElementById("diverror" + FieldID).style.display = "none";

                                if (ext_Form_fields.design?.questions[Attr.parameters.id]?.validation?.rq === true && document.getElementById(FieldID).value === "") {
                                    document.getElementById(FieldID).focus();
                                    document.getElementById("diverror" + FieldID).style.display = "";
                                    return;
                                }

                                hasCustomForm = true;
                                description = description + "\n" + ext_Form_fields.design?.questions[Attr.parameters.id].label + " : " + document.getElementById(FieldID).value;

                                //if (["cl", "cd"].includes(ext_Form_fields.design?.questions[Attr.parameters.id].type)) {
                                //    let value = {};
                                //    value["id"] = document.getElementById(FieldID).value;
                                //    objField[FieldID] = value;
                                //}
                                //else {
                                //    objField[FieldID] = document.getElementById(FieldID).value;
                                //}
                            }
                        }
                    }
                }
            }
        }

        console.log(description);

        if (hasCustomForm === true) {

            let assignee = {};
            assignee["id"] = accountInfo && JSON.parse(accountInfo.data.response)?.accountId;

            let project = {};
            project["key"] = "CENTRAL";

            let issuetype = {};
            issuetype["id"] = selItem.issueTypeId;

            let fields = {};
            fields["project"] = project;
            fields["reporter"] = assignee;
            fields["issuetype"] = issuetype;
            fields["summary"] = document.getElementById("summary") ? document.getElementById("summary").value : selItem.name;
            fields["description"] = description;

            objReq["fields"] = fields;
        }
        else {

            objReq["serviceDeskId"] = "1";
            objReq["requestTypeId"] = selItem.id;
            objReq["raiseOnBehalfOf"] = accountInfo && JSON.parse(accountInfo.data.response)?.accountId;

            objField["summary"] = document.getElementById("summary") ? document.getElementById("summary").value : selItem.name;
            objField["description"] = description;
            objReq["requestFieldValues"] = objField;
        }

        setselIndex(null);
        setselItem(null);

        setTimeout(() => {
            setselIndex(0);
            setselItem(JIRAItems[0]);
        }, 1000)

        //const token = localStorage.getItem('accessToken');

        if (navigator.onLine) {
            setIsLoading(true);

            fetch(`/JiraApiBasic/PostJIRARequest?hasCustomForm=${hasCustomForm}`, {
                method: "POST",
                body: JSON.stringify({
                    data: objReq
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(response => response.json())
                .then(async (j) => {
                    if (j?.message) {

                        if (j.message.issueKey !== null && j.message.issueKey !== "") {
                            setSuccessMessage(j.message.issueKey);
                            const issueIdOrKey = j.message.issueId;

                            const isPublic = true;

                            if (AttachmentFiles && AttachmentFiles.length > 0) {
                                const tempAttachmentIds = [];

                                for (let i = 0; i < AttachmentFiles.length; i++) {
                                    const formData = new FormData();
                                    formData.append("file", AttachmentFiles[i]);

                                    const uploadResponse = await fetch(`/JiraApiBasic/UploadTemporaryFileToJIRA?serviceDeskId=1`, {
                                        method: "POST",
                                        body: formData
                                    });

                                    if (uploadResponse.ok) {
                                        const uploadResult = await uploadResponse.json();
                                        const response = JSON.parse(uploadResult.response);
                                        const tempFileId = response.temporaryAttachments[0].temporaryAttachmentId;

                                        tempAttachmentIds.push(tempFileId);
                                    }
                                }

                                // Once all files are uploaded, attach them to the ticket
                                if (tempAttachmentIds.length > 0) {
                                    fetch(`/JiraApiBasic/AttachFileToJIRAIssue?issueIdOrKey=${issueIdOrKey}&isPublic=${isPublic}`, {
                                        method: 'POST',
                                        body: JSON.stringify(tempAttachmentIds),
                                        headers: {
                                            'Content-type': 'application/json; charset=UTF-8',
                                        },
                                    })
                                        .then(response => response.json())
                                        .then(attachResponse => {
                                            console.log('Attachment Response:', attachResponse);
                                        })
                                        .catch(error => {
                                            console.error('Error attaching file:', error);
                                        });
                                }

                                setAttachmentFiles(null);
                            }
                        }
                        setIsLoading(false);
                        //alert("Ticket created successfully.");

                    } else {
                        setIsLoading(false);
                        alert("Failed to create ticket.");
                    }
                }).catch(error => {
                    setIsLoading(false);
                    console.error("Error creating ticket:", error);
                    alert("There was an error processing your request.");
                });
        }
        else {
            setIsLoading(true);

            objReq["hasCustomForm"] = hasCustomForm;

            StoreJIRAReq(objReq, AttachmentFiles);
            setIsLoading(false);
            alert("You request is submitted successfully.")
        }
    }

    //#region Attachments

    const [AttachmentFiles, setAttachmentFiles] = useState();

    const handleFiles = (e) => {
        let files = [];
        let fileNames = "";
        for (var i = 0; i < e.target.files.length; i++) {

            files.push(e.target.files[i]);
            if (fileNames === "") {
                fileNames = e.target.files[i].name
            }
            else {
                fileNames = fileNames + ", " + e.target.files[i].name;
            }
        }

        if (fileNames !== "") {
            setAttachmentFiles(files);
            console.log(files);
        }
    }

    //#endregion

    return (JIRAItems !== null && JIRAItems.length > 0
        ?
        <div>
            {isLoading === true &&
                <>
                    <div class="loading-overlay">
                        <div class="loader"></div> Please wait while we create your ticket...
                    </div>
                </>
            }
            {SuccessMessage !== "" &&
                <>
                    <Row>
                        <Col className={"pb10"} >
                            <span className="SuccessMessage">Your ticket is created successfully.
                                &nbsp;&nbsp;<a target="_blank" rel="noreferrer" href={"https://rfdscentral.atlassian.net/browse/" + SuccessMessage}>Click here</a> to Open the Ticket.</span>
                        </Col>
                    </Row>
                </>
            }
            <Row>
                <Col className={"pb10"} >
                    {/*<Link to="/" className={"linkNone"}>&#60; Back</Link>*/}
                </Col>
            </Row>
            <Row>
                <Col className={"pb10"}>
                    <h3>{id === "1" ? "IT Service request" : "Report an incident"}</h3>
                </Col>
            </Row>
            <div>
                <Row className={"whiteBG"}>
                    <Col className={"p20"}>
                        {selItem !== null ?
                            <>
                                <SelectedItemView selItem={selItem} setOpenItems={setOpenItems} OpenItems={OpenItems} />
                            </>
                            : null}

                        {OpenItems && JIRAItems.map((item, index) =>
                            <>
                                <ItemData item={item} setOpenItems={setOpenItems} selIndex={selIndex} index={index} />
                            </>
                        )}

                    </Col>
                </Row>
                <br />
                <br />
                <Row className={"whiteBG"}>
                    <Col>
                        <Row>
                            <Col className={"p20"}>
                                {selItem?.requestFields?.requestTypeFields?.length > 0 && selItem?.requestFields?.requestTypeFields.map((item, index) =>
                                    <>
                                        <Row className={"p10"}>
                                            <div className="col-12">
                                                {item.name}
                                                {["summary", "description"].includes(item.fieldId) ?
                                                    <span class="requiredValidate">&nbsp;*</span> : null
                                                }

                                                {item.fieldId.indexOf("customfield_") >= 0 && item?.required === true ?
                                                    <span class="requiredValidate">&nbsp;*</span> : null
                                                }
                                            </div>
                                        </Row>
                                        <Row className={"p10"}>
                                            <div className="col-12">
                                                {(item.jiraSchema.system === "summary" ||
                                                    (item.fieldId.indexOf("customfield_") >= 0 && item?.jiraSchema?.type === "string")
                                                ) &&
                                                    <>
                                                        <Input id={item.fieldId} type="text" autocomplete="off" />
                                                        <div id={"diverror" + item.fieldId} className="errorMessage" style={{ display: "none" }}>This field is required.</div>
                                                    </>
                                                }
                                                {(item.jiraSchema.system === null && item.jiraSchema.type === "option") &&
                                                    <>
                                                        <Input id={item.fieldId} type="select" autocomplete="off">
                                                            <option value=""></option>
                                                            {item?.validValues && item.validValues.map((el) =>
                                                                <option value={el.value}>{el.label}</option>
                                                            )}
                                                        </Input>
                                                        <div id={"diverror" + item.fieldId} className="errorMessage" style={{ display: "none" }}>This field is required.</div>
                                                    </>
                                                }
                                                {(item.jiraSchema.system === null && item.jiraSchema.type === "date") &&
                                                    <>
                                                        <Input id={item.fieldId} type="date" autocomplete="off" />
                                                        <div id={"diverror" + item.fieldId} className="errorMessage" style={{ display: "none" }}>This field is required.</div>
                                                    </>
                                                }
                                                {/*{(item.jiraSchema.system === "description") &&*/}
                                                {/*    <>*/}
                                                {/*        <CKEditor editor={ClassicEditor} config={editorConfiguration}*/}
                                                {/*            onReady={(editor) => {*/}

                                                {/*            }}*/}
                                                {/*            onChange={(event, editor) => {*/}
                                                {/*                const data = editor.getData();*/}
                                                {/*                setreqDesc(data);*/}
                                                {/*            }}*/}
                                                {/*        />*/}
                                                {/*        <div id={"diverror" + item.fieldId} className="errorMessage" style={{ display: "none" }}>This field is required.</div>*/}
                                                {/*    </>*/}
                                                {/*}*/}

                                                {(item.jiraSchema.system === "description") &&
                                                    <>
                                                        <Input id={item.fieldId} type="textarea" onChange={(e) => { setreqDesc(e.target.value); }} rows="10" autocomplete="off" />
                                                        <div id={"diverror" + item.fieldId} className="errorMessage" style={{ display: "none" }}>This field is required.</div>
                                                    </>
                                                }

                                                {(item.jiraSchema.system === "attachment") &&
                                                    <>
                                                        <Input id={item.fieldId} type="file" autocomplete="off" multiple onChange={handleFiles} />
                                                        <div id={"diverror" + item.fieldId} className="errorMessage" style={{ display: "none" }}>This field is required.</div>
                                                    </>
                                                }
                                            </div>
                                        </Row>
                                        {item.description &&
                                            <Row className={"p10"}>
                                                <div className="col-12">
                                                    <small>{item.description}</small>
                                                </div>
                                            </Row>
                                        }
                                    </>
                                )}

                                {selItem?.requestFields?.requestTypeFields?.length <= 1 && selItem?.ext_form !== null && selItem?.ext_form?.design?.layout !== null &&
                                    <>
                                        {selItem?.ext_form?.design?.layout.map((item) =>
                                            <>
                                                {item.content.map((content) =>
                                                    <>
                                                        <Row className={"p10"}>
                                                            {content.content !== null && content?.content !== undefined && content.content.map((fields) =>
                                                                <>
                                                                    {fields?.content !== null && fields.content.length === 1 &&
                                                                        <>
                                                                            {selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id] !== null &&
                                                                                selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id] !== undefined &&
                                                                                <>
                                                                                    <Col>
                                                                                        {selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].label}
                                                                                        {selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id]?.validation?.rq === true ?
                                                                                            <span class="requiredValidate">&nbsp;*</span> : null
                                                                                        }
                                                                                        <br />
                                                                                        {selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].type === "tl" &&
                                                                                            <>
                                                                                                <Input id={selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].jiraField} type="text" autocomplete="off" />
                                                                                            </>
                                                                                        }

                                                                                        {selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].type === "da" &&
                                                                                            <>
                                                                                                <Input id={selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].jiraField} type="date" autocomplete="off" />
                                                                                            </>
                                                                                        }

                                                                                        {["cl", "cd"].includes(selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].type) &&
                                                                                            <>
                                                                                                <Input id={selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].jiraField} type="select" autocomplete="off">
                                                                                                    {selItem?.ext_fields !== undefined && selItem?.ext_fields.fields !== undefined && selItem?.ext_fields.fields[fields.content[0].attrs.parameters.id] !== undefined &&
                                                                                                        selItem?.ext_fields.fields[fields.content[0].attrs.parameters.id]?.choices !== undefined &&
                                                                                                        <>
                                                                                                            <option value=""></option>
                                                                                                            {selItem?.ext_fields.fields[fields.content[0].attrs.parameters.id]?.choices.map((el) =>
                                                                                                                <>
                                                                                                                    <option value={el.name}>{el.name}</option>
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    }
                                                                                                </Input>
                                                                                            </>
                                                                                        }

                                                                                        {selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].type === "rt" &&
                                                                                            <>
                                                                                                <Input id={selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].jiraField} type="textarea" onChange={(e) => { setreqDesc(e.target.value); }} rows="10" autocomplete="off" />
                                                                                            </>
                                                                                        }

                                                                                        {["tl", "da", "cl", "cd", "rt"].includes(selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].type) &&
                                                                                            <>
                                                                                                <div id={"diverror" + selItem?.ext_form?.design?.questions[fields.content[0].attrs.parameters.id].jiraField} className="errorMessage" style={{ display: "none" }}>This field is required.</div>
                                                                                            </>
                                                                                        }
                                                                                    </Col>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            )}

                                                            {content?.attrs !== undefined && content?.attrs?.parameters?.id !== undefined &&
                                                                <>
                                                                    <Col>
                                                                        {selItem?.ext_form?.design?.questions[content.attrs.parameters.id].label}
                                                                        {selItem?.ext_form?.design?.questions[content.attrs.parameters.id]?.validation?.rq === true ?
                                                                            <span class="requiredValidate">&nbsp;*</span> : null
                                                                        }
                                                                        <br />
                                                                        {selItem?.ext_form?.design?.questions[content.attrs.parameters.id].type === "tl" &&
                                                                            <>
                                                                                <Input id={selItem?.ext_form?.design?.questions[content.attrs.parameters.id].jiraField} type="text" autocomplete="off" />
                                                                            </>
                                                                        }

                                                                        {selItem?.ext_form?.design?.questions[content.attrs.parameters.id].type === "da" &&
                                                                            <>
                                                                                <Input id={selItem?.ext_form?.design?.questions[content.attrs.parameters.id].jiraField} type="date" autocomplete="off" />
                                                                            </>
                                                                        }

                                                                        {["cl", "cd"].includes(selItem?.ext_form?.design?.questions[content.attrs.parameters.id].type) &&
                                                                            <>
                                                                                <Input id={selItem?.ext_form?.design?.questions[content.attrs.parameters.id].jiraField} type="select" autocomplete="off">
                                                                                    {selItem?.ext_fields !== undefined && selItem?.ext_fields.fields !== undefined && selItem?.ext_fields.fields[content.attrs.parameters.id] !== undefined &&
                                                                                        selItem?.ext_fields.fields[content.attrs.parameters.id]?.choices !== undefined &&
                                                                                        <>
                                                                                            <option value=""></option>
                                                                                            {selItem?.ext_fields.fields[content.attrs.parameters.id]?.choices.map((el) =>
                                                                                                <>
                                                                                                    <option value={el.name}>{el.name}</option>
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    }
                                                                                </Input>
                                                                            </>
                                                                        }

                                                                        {selItem?.ext_form?.design?.questions[content.attrs.parameters.id].type === "rt" &&
                                                                            <>
                                                                                <Input id={selItem?.ext_form?.design?.questions[content.attrs.parameters.id].jiraField} type="textarea" onChange={(e) => { setreqDesc(e.target.value); }} rows="10" autocomplete="off" />
                                                                            </>
                                                                        }

                                                                        {["tl", "da", "cl", "cd", "rt"].includes(selItem?.ext_form?.design?.questions[content.attrs.parameters.id].type) &&
                                                                            <>
                                                                                <div id={"diverror" + selItem?.ext_form?.design?.questions[content.attrs.parameters.id].jiraField} className="errorMessage" style={{ display: "none" }}>This field is required.</div>
                                                                            </>
                                                                        }
                                                                    </Col>
                                                                </>
                                                            }
                                                        </Row >
                                                    </>
                                                )}
                                            </>

                                        )}
                                    </>
                                }
                            </Col>
                        </Row>
                        {(selItem?.requestFields?.requestTypeFields?.length > 0 ||
                            (selItem?.requestFields?.requestTypeFields?.length <= 1 && selItem?.ext_form !== null && selItem?.ext_form?.design?.questions !== null)) &&
                            <>
                                <Row>
                                    <Col className={"text-right"}>
                                        <Button className={"btn-primary"} onClick={(e) => { e.preventDefault(); CreateServiceReq(); }}>Send</Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>

            </div >
            <br />
            <br />
        </div >
        : null
    );
}

export default ServiceReq;