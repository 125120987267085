import { v4 as uuidv4 } from 'uuid';
var db;
export function CreateDB() {
    const request = window.indexedDB.open("rfds_IT_portal", 1);
    request.onupgradeneeded = function (event) {
        var db2 = event.target.result;
        var objectStore = db2.createObjectStore("jira_data", {
            keyPath: "id",
            autoIncrement: true,
        });

        var objectStore1 = db2.createObjectStore("user", {
            keyPath: "id",
            autoIncrement: true,
        });

        var objectStore2 = db2.createObjectStore("requests", {
            keyPath: "id",
            autoIncrement: true,
        });
    };
    request.onerror = function (event) {

    };
    request.onsuccess = function (event) {

    };
}

export function StoreJIRAData(data) {
    let tableName = "jira_data"
    const request = window.indexedDB.open("rfds_IT_portal", 1);
    request.onupgradeneeded = function (event) {
        var db2 = event.target.result;
        var objectStore = db2.createObjectStore(tableName, {
            keyPath: "id",
            autoIncrement: true,
        });
    };
    request.onerror = function (event) {

    };
    request.onsuccess = function (event) {

        db = event.target.result;

        const transaction = db.transaction([tableName], "readwrite");
        transaction.oncomplete = (event) => {
            console.log("Transaction completed.");
        };

        transaction.onerror = (event) => {

        };

        const objectStore = transaction.objectStore(tableName);
        const objectStoreRequestClear = objectStore.clear();

        objectStoreRequestClear.onsuccess = (event) => {
        };

        data.values.map((weather) => {
            const objectStoreRequest = objectStore.add(weather);
            objectStoreRequest.onsuccess = (event) => {
            };
        })
    };
}

export function StoreFetchData(data) {
    const request = window.indexedDB.open("rfds_IT_portal", 1);
    request.onupgradeneeded = function (event) {
        var db2 = event.target.result;
        if (event.oldVersion < 1) {
            var objectStore = db2.createObjectStore("weather_data", {
                keyPath: "id",
                autoIncrement: true,
            });
        }
    };
    request.onerror = function (event) {
    };
    request.onsuccess = function (event) {
        db = event.target.result;

        const transaction = db.transaction(["weather_data"], "readwrite");
        transaction.oncomplete = (event) => {
        };

        transaction.onerror = (event) => {
        };

        const objectStore = transaction.objectStore("weather_data");
        data.map((weather) => {
            const objectStoreRequest = objectStore.add(weather);
            objectStoreRequest.onsuccess = (event) => {
            };
        })
    };
}

export async function ReadIndexDBData(Table) {
    return new Promise(function (resolve) {
        let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        var open = indexedDB.open("rfds_IT_portal", 1);

        open.onsuccess = function () {
            let db = open.result;
            let tx = db.transaction(Table, "readwrite");
            var store = tx.objectStore(Table);
            store.getAll().onsuccess = function (event) {
                return resolve(event.target.result);
            }
        }
    });
}

export function StoreJIRAReq(data, AttachmentFiles) {

    let tableName = "requests";
    const request = window.indexedDB.open("rfds_IT_portal", 1);

    request.onupgradeneeded = function (event) {
        var db2 = event.target.result;
        db2.createObjectStore(tableName, {
            keyPath: "id",
            autoIncrement: true,
        });
    };

    // Error handling
    request.onerror = function (event) {
        console.error("IndexedDB error:", event.target.error);
    };

    // Handle successful database open
    request.onsuccess = function (event) {
        const db = event.target.result;
        // Generate a unique ID for the ticket
        const id = uuidv4();
        data.ticketUid = id;

        // Prepare to read files

        const fileReadPromises = AttachmentFiles ? AttachmentFiles.map((att) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(att);
                reader.onload = function () {
                    resolve({
                        file: reader.result,
                        type: att.type,
                        name: att.name,
                        size: att.size,
                        ticketUid: id,
                    });
                };
                reader.onerror = reject;
            });
        }) : [];

        // Wait for all file reads to complete
        Promise.all(fileReadPromises).then((files) => {
            // Begin a transaction to add the data and files
            const transaction = db.transaction([tableName], "readwrite");
            const objectStore = transaction.objectStore(tableName);
            objectStore.add(data);
            // Add all attachments
            files.forEach((file) => {
                objectStore.add(file);
            });

            transaction.oncomplete = (event) => {
                console.log("All data and files added successfully!");
            };

            transaction.onerror = (event) => {
                console.error("Transaction error:", transaction.error);
            };
        }).catch((error) => {
            console.error("Error reading files:", error);
        });
    };
}
export function ClearTable(tableName) {

    const request = window.indexedDB.open("rfds_IT_portal", 1);
    request.onupgradeneeded = function (event) {

    };
    request.onerror = function (event) {

    };
    request.onsuccess = function (event) {
        db = event.target.result;

        const transaction = db.transaction([tableName], "readwrite");
        transaction.oncomplete = (event) => {
        };
        transaction.onerror = (event) => {
        };

        const objectStore = transaction.objectStore(tableName);
        const objectStoreRequestClear = objectStore.clear();

        objectStoreRequestClear.onsuccess = (event) => {
        };
    };
}

export function DeleteIndexDB() {
    return new Promise((resolve, reject) => {
        const deleteDbRequest = indexedDB.deleteDatabase("rfds_IT_portal");
        deleteDbRequest.addEventListener('error', (e) => {
            console.log('delete error', e);
            reject(e);
        });
        deleteDbRequest.addEventListener('blocked', (e) => {
            console.log('delete blocked', e);
            reject(e);
        });
        deleteDbRequest.addEventListener('upgradeneeded', (e) => {
            console.log('delete upgradeneeded', e);
            reject(e);
        });
        deleteDbRequest.addEventListener('success', (e) => {
            console.log('delete success', e);
            resolve(e);
        });
    })
}