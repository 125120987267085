import React from 'react';
import { Row, Card, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import img_1 from '../assets/images/service-request.svg';
import img_2 from '../assets/images/incident-report.svg';
import { CreateDB, ReadIndexDBData, ClearTable, StoreJIRAData } from '../indexedDB'
import { useEffect, useState } from "react";
import my_test_data from './test_data/rfds_IT_portal_exported_data.json'

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        CreateDB();
        JIRAData();
    }, []);

    const JIRAData = async () => {
        const data = await ReadIndexDBData("jira_data");
        if (data === null || data === undefined || data?.length === undefined || data.length === 0) {
            if (navigator.onLine) {
                setIsLoading(true);
                fetch('/JiraApiBasic/GetJIRAData').then(function (serverPromise) {
                    serverPromise.json()
                        .then(function (j) {
                            StoreJIRAData(j);
                            setIsLoading(false);
                        })
                        .catch(function (e) {
                        });
                })
            }
            else {
                StoreJIRAData(my_test_data);
            }
        }
    }

    return (
        <div>
            <Row>
                {isLoading === true &&
                    <>
                        <div class="loading-overlay">
                            <div class="loader"></div> Please wait while we load request data...
                        </div>
                    </>
                }
                <div className="col-6">
                    <Card className="text-center" style={{ display: "block" }} >
                        <Link to="/service_req/1" className={"linkNone"}>
                            <CardImg alt="Card image cap" src={img_1} className={"home_img_items"} />
                            <hr />
                            <CardBody className="p-4">
                                <CardTitle tag="h3" className={"colorFont"}>IT Service request</CardTitle>
                                <br />
                                <CardSubtitle className={"minHeightCardSummary"}>Request new Hardware, Request new Software, other</CardSubtitle>
                            </CardBody>
                        </Link>
                    </Card>
                </div>
                <div className="col-6">
                    <Card className="text-center" style={{ display: "block" }} >
                        <Link to="/service_req/4" className={"linkNone"}>
                            <CardImg alt="Card image cap" src={img_2} className={"home_img_items"} />
                            <hr />
                            <CardBody className="p-4">
                                <CardTitle tag="h3" className={"colorFont"}>Report an incident</CardTitle>
                                <br />
                                <CardSubtitle className={"minHeightCardSummary"}>Corporate, Aviation, Base Operations, Health Services, Report broken hardware
                                </CardSubtitle>
                            </CardBody>
                        </Link>
                    </Card>
                </div>
            </Row>
            <br /><br />
        </div>
    );
}

export default Home;